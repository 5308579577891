// the translations
// (tip move them in a JSON file and import them)
const translate = {
  en: {
    translation: {
      'Biểu quyết thông qua nội dung bầu cử':
        'Voting to approve the election content',
      'CĐ đã ủy quyền': 'Authorized Shareholder',
      'Ủy quyền toàn bộ số quyền biểu quyết cho:':
        'Authorize all voting rights to:',
      'Hoặc nhập thông tin người được ủy quyền':
        'Or enter authorized person information',
      'Cổ đông vui lòng tải về giấy ủy quyền để thực hiện ký (đóng dấu)':
        'Shareholder please download the power of attorney to sign (stamped)',
      'Xảy ra sự cố khi tải tệp. Vui lòng thử lại':
        'Error in loading file. Please try again.',
      'Người được ủy quyền không được nhận ủy quyền':
        'The authorized person cannot receive authorization',
      'Quên mật khẩu?': 'Forgot password?',
      'Vui lòng tải lên giấy ủy quyền đã đóng dấu':
        'Please upload Power of attorney (stamped)',
      'Cổ đông chưa cập nhật SĐT hoặc sai định dạng Vui lòng liên hệ với Ban tổ chức để cập nhật thông tin':
        'Shareholders have not updated phone number, Please contact the Organizing Committee to update information. Thank you!',

      'Đăng nhập cho Khách mời': 'Guest Login',
      'Đăng nhập cho Cổ đông': 'Shareholders Login',
      'Tôi muốn thực hiện ủy quyền': 'I want to do Authorization',
      'Tôi xác nhận tham dự Đại hội': 'I confirm to attend the Meeting',
      'Hoàn thành thủ tục tham dự đại hội':
        'Completing the procedure to attend the General Meeting',
      'CP sở hữu/đại diện': 'Share number owned/represented',
      'Xem Đại hội trực tuyến': 'Online meeting link',
      'tài liệu Đại hội': 'Meeting Documents',
      'Cổ đông': 'Shareholder',
      'Hoàn thành': 'Submit',
      'Dữ liệu không hợp lệ': 'Invalid value',
      'Bạn có chắc chắn muốn gửi biểu quyết':
        'Are you sure you want to submit this vote?',
      'Nội dung biểu quyết đã đóng': 'The topic is closed',
      'Cuộc họp không tồn tại': 'The meeting is not exist',
      'Khách mời': 'Guest',
      'Đăng nhập thất bại': 'Login failed. Please try again.',
      'Tài khoản không tồn tại': 'Invalid account.',
      'Vui lòng nhập đầy đủ thông tin.': 'Please fill all the information.',
      'Gửi tất cả biểu quyết': 'Submit all',
      'Nếu muốn thực hiện biểu quyết tất cả số phiếu bầu, Cổ đông vui lòng thực hiện biểu quyết cho từng Cổ đông trong danh sách nhận uỷ quyền':
        'If you want to vote for all votes, please vote for each shareholder in the authorized list.',
      'Tổng số phiếu bầu còn lại là số phiếu của các Cổ đông uỷ quyền không thoả mãn việc chia đều':
        'The total number of remaining votes is the number of votes of authorized shareholders who do not satisfy the even distribution.',
      all: 'All',
      // 'Cổ đông chưa cập nhật SĐT Vui lòng liên hệ với Ban tổ chức để cập nhật thông tin':
      //   'Shareholders have not updated phone number, Please contact the Organizing Committee to update information Thank you!',

      input: {
        id_number: 'Username (ID/Passport/Business Registration No.)',
        id_number_2: 'Username',
        password: 'Password (The password in the invitation letter)',
        password_2: 'Password',
        authorized_name: 'Full name of authorized person',
      },

      conf_time_modal: {
        desc_1: {
          part_1: 'Shareholder can only join the meeting after',
          part_2: '.',
        },
        desc_2: 'Best regards!',
        desc_3: 'The meeting has ended. Thank you for your participation !',
      },

      CP: 'Shares',
      title: {
        home: {
          article: {
            meeting: 'meeting',
            information: 'information',
          },
          topics: {
            meeting: 'meeting',
            documents: 'documents',
          },
          shareholder: {
            voting: 'voting',
            _blank: '',
          },
        },
      },

      'Thực hiện biểu quyết': 'Make a vote',

      otp: 'OTP',
      password: 'Password',
      'Gửi mã OTP': 'Send me OTP',
      'Tiếp tục với': 'Continue with',

      validate: {
        required: 'This field is required',
        pattern: {
          password: 'Password must be 6-50 char in length',
          only_number: 'Only number is valid',
        },
        wrong_pattern: 'Invalid value',
      },
      'Please check your SMS to get confirm OTP':
        'Please check your SMS to get confirm OTP',

      'Đăng nhập biểu quyết': 'Sign in to vote',
      'Cổ đông không thể gửi ý kiến trong thời điểm này':
        'Discussion session is closed.',
      'Đại hội Cổ đông thường niên 2022':
        'Annual General Meeting of Shareholders in 2022',
      'Thời gian': 'Time',
      'Thao tác': 'Action',
      Từ: 'From',
      'Cổ đông đã bầu cho nội dung này': 'Shareholders voted for this content',
      'Chưa có dữ liệu': 'No data yet',
      'Địa điểm điều hành Đại hội': 'Meeting venue',
      'Hướng dẫn dành cho Cổ đông': 'Usage for shareholder',
      'Nội dung chính': 'Main content',
      'Quay lại': 'Back',
      'Đồng ý': 'Yes',
      'Vui lòng nhập mã OTP': 'Enter the OTP code sent to your phone number',
      'ĐÃ GỬI OTP THÀNH CÔNG': 'OTP code is successfully',
      'Gửi OTP thành công':
        'OTP code is sent to your phone number. Please check and enter OTP code to continue',
      'THÔNG BÁO': 'Notification',
      'Nhập OTP': 'Enter OTP code',
      'Đăng nhập': 'Log in',
      'Vui lòng nhập mật khẩu': 'Please enter password',
      'Vui lòng nhập mật khẩu trong thư mời':
        'Please enter the password in the invitation letter',
      'TRỞ VỀ TRANG CHỦ': 'BACK TO HOME',
      'Vui lòng điền đầy đủ thông tin': 'Please fill in full information',
      'tiếp tục với mật khẩu': 'Continue with your password',
      'tiếp tục với otp': 'Continue with your OTP code',
      'Vui lòng nhập số CMND/CCCD': 'Username',
      'Không nhận được OTP?': "Didn't receive OTP?",
      'Gửi lại OTP': 'Resend OTP',
      'quý Cổ đông vui lòng đăng nhập để tham gia biểu quyết':
        'Shareholder sign in to vote, please',
      'Nhập nội dung': 'Enter information',
      'LƯU THÔNG TIN ỦY QUYỀN': 'Store authorization information',
      'Sau khi hoàn thành ủy quyền bạn không thể thay đổi các thông tin đã gửi cho Ban tổ chức đại hội Vui lòng chọn Đồng ý nếu bạn đã chắc chắn với quyết định của mình Chọn quay lại để sửa thông tin Người được ủy quyền':
        'After authorization is finished, you cannot change the information sent to the board of organizers. Please choose “Agree” if you are sure with your decision. Choose “Back” to change the information of authorized person',
      'Thông tin người được ủy quyền': 'Information of authorized person',
      'Họ và tên': 'Full name',
      'Số điện thoại': 'Phone number',
      'Tài khoản': 'Account',
      'Mật khẩu': 'Password',
      'CMND/CCCD': 'ID number',
      'Nhập số cổ phần ủy quyền': 'Enter number of authorized shares',
      'Nơi cấp': 'Issued by',
      'Ngày cấp': 'Issue date',
      'Thông tin người ủy quyền': 'Information of mandator',
      'Số cổ phần có thể ủy quyền': 'Number of authorized shares',
      'Người đại diện phần vốn góp (dành cho Cổ đông là tổ chức)':
        'Representative of contributed capital (for shareholder as organization)',
      'Download không thành công': 'Download unsuccessfully',
      'Họ tên': 'Full name',
      'Điện thoại': 'Phone number',
      'Số điện thoại nhận OTP': 'Phone number to receive OTP:',
      'Cổ phần ủy quyền': 'Authorized shares',
      'Trạng thái': 'Status',
      Lỗi: 'Error',
      'Ủy quyền': 'Authorization',
      'Danh sách ủy quyền': 'Authorization list',
      Đóng: 'Close',
      'Để hoàn tất quá trình ủy quyền, quý Cổ đông vui lòng in thư ủy quyền và gửi đến:':
        'To complete the authorization process, shareholder needs to print authorization letter and send it.',
      'Địa chỉ': 'Address',
      'để phục vụ cho ban tổ chức xử lý thông tin ủy quyền':
        'to serve the board of organizers to process authorization information',
      'ĐÃ LƯU THÔNG TIN ỦY QUYỀN': 'AUTHORIZATION INFORMATION IS STORED',
      'LƯU THÔNG TIN ỦY QUYỀN KHÔNG THÀNH CÔNG':
        'AUTHORIZATION INFORMATION IS NOT STORED',
      'Địa chỉ nhận thư ủy quyền': 'Receiving place of authorization letter',
      'Thời gian nhận thư ủy quyền': 'Time to receive authorization letter',
      'Tải mẫu form ủy quyền': 'Download form of authorization letter',
      'Checkin thành công!': 'successful checkin!',
      'CHECKIN KHÔNG THÀNH CÔNG': 'Unsuccessful checkin',
      'Checkin Online': 'Checkin Online',
      'Chú ý':
        'Note: After checkin, if shareholder doesn’t vote, your vote will be invalid',
      'Vui lòng chọn':
        'Please choose "Checkin" if your want to check in right now. Choose “Back” to check in later.',
      'Đăng xuất': 'Log out',
      'Gửi biểu quyết thành công': 'Voting submitted successfully',
      'BIỂU QUYẾT KHÔNG THÀNH CÔNG': 'VOTING UNSUCCESSFULLY',
      'Biểu quyết thành công': 'Voting successfully',
      'Chi tiết': 'Detail',
      'Đã gửi biểu quyết': 'Vote is sent',
      'Vui lòng lựa chọn ý kiến biểu quyết': 'Please choose a voting opinion!',
      'Nội dung này chưa mở biểu quyết': 'Voting time is closed!',
      'Không thể thay đổi biểu quyết đã gửi':
        'Shareholders voted for this content.',
      'Sau khi gửi biểu quyết đến ban tổ chức':
        'After vote is sent to the board of organizers, you cannot change it.',
      'Bạn có muốn tiếp tục?': 'Do you want to continue?',
      'Đã hoàn thành': 'Done',
      'Gửi biểu quyết': 'Submit',
      'Qúy Cổ đông vui lòng nhấn Checkin để tiến hành checkin truớc khi biểu quyết':
        'Shareholder presses “checkin” to check in before voting',
      Hiện: 'Show',
      Ẩn: 'Hide',
      'GỬI BIỂU QUYẾT BẦU DỒN': 'Send cumulative',
      '(Ủy quyền thêm)': '(Authorize more)',
      'Tổng số phiếu có thể bầu': 'Total number of possible votes',
      'Cổ đông đã nhập quá số phiếu có thể bầu':
        'Shareholders have entered more than enough votes to vote',
      'Tổng số phiếu còn lại': 'Total number of votes left',
      'Tỷ lệ còn lại': 'Remaining rate',
      'Tổng tỷ lệ bầu không thể quá 100%': 'Total rate cannot be over 100%',
      'Bầu đều': 'Vote evenly',
      'Tích để bầu đều': 'Tick for even voting',
      'Nhập số phiếu bầu': 'Enter the number of votes',
      'Nhập tỷ lệ bầu (%)': 'Enter the vote rate (%)',
      'Số phiếu không hợp lệ': 'Number of invalid votes',
      Còn: 'The rest',
      'mục chưa bỏ phiếu': 'Section hasn’t been voted yet',
      'Đã hoàn thành bỏ phiếu': 'Voting is done',
      'Cổ đông, đại diện cho': 'Shareholders, representative of',
      'cổ phần': 'Share',
      'Mỗi cổ phần tương ứng với một phiếu biểu quyết':
        'Each share is equal to a vote',
      'Tên doanh nghiệp': 'Name of enterprise',
      'Mã số doanh nghiệp': 'Code of enterprise',
      'Thời gian họp': 'Time',
      'Bế mạc': 'End',
      'Địa điểm họp': 'Venue',
      'Tổng số Cổ đông tham dự': 'Number of participating shareholders',
      'Chủ tọa Đại hội': 'Chairman of AGM',

      'Thư ký Đại hội': 'Secretary',
      'Tán thành': 'Affirmative votes',
      'Gửi tất cả': 'Submit all',
      'Vui lòng chọn ít nhất 1 biểu quyết trước khi biểu quyết':
        'Please choose at least 1 vote before voting',
      'Nội dung': 'Content',
      'Đã biểu quyết trước đó': 'Vote previously',
      'Chưa chọn biểu quyết': 'Hasn’t voted',
      'Biểu quyết không thành công': 'Vote unsuccessfully',
      'Vui lòng chọn biểu quyết cho câu hỏi này trước':
        'Please choose voting for this question first',
      'Chưa biểu quyết': 'Hasn’t voted',
      'Đã biểu quyết': 'Voted',
      STT: 'No.',
      'Biểu quyết': 'Voting',
      'Tải danh sách ý kiến lỗi': 'Cannot download the list of opinions',
      'Danh sách ý kiến': 'List of opinions',
      'Ý kiến': 'Opinion',
      'Ngày gửi': 'Sending date',
      'Số CMND/CCCD/MSDN': 'ID/Passport/Business Registration No.',
      'Số cổ phần sở hữu': 'Share number owned/represented',
      'Thông qua thủ tục khai mạc Đại hội': 'Opening Ceremony',

      'Biểu quyết thông qua các tờ trình': 'Voting to approve documents',

      'Biểu quyết thông qua biên bản và nghị quyết cuộc họp':
        'Voting to approve Minutes and Resolutions',
      'Bầu cử': 'Cumulative voting',
      'Nội dung (bầu dồn phiếu)': 'Content (cumulative voting)',
      'Cổ đông tiến hành gửi biểu quyết cho': 'Shareholder send voting to',
      mục: 'section',
      'Tổng số nội dung tán thành': 'Total Affirmative',
      'Tổng số nội dung không tán thành': 'Total Nagative',
      'Tổng số nội dung không ý kiến': 'Total Abstention',
      'Tổng số nội dung chưa biểu quyết': 'Total not yet vote',
      'Nội dung Cổ đông không biểu quyết':
        'If shareholder doesn’t vote for a section, that vote will be invalid for only this section.',
      'Cổ đông có chắc chắn gửi biểu quyết của mình?':
        'Are you sure to submit these votes?',
      'Tải xuống file kết quả biểu quyết': 'Download results',
      'Thông tin Đại hội': 'Information about the Meeting',

      'Trạng thái biểu quyết': 'Voting status',

      'Phiên làm việc đã kết thúc': 'the session ends',

      'Phiên đã kết thúc hoặc không hợp lệ Vui lòng đăng nhập lại để tiếp tục sử dụng':
        'The session ends or is invalid. Please sign in again to continue using',
      'Cảm ơn quý Cổ đông':
        'Thank you to the Shareholders who registered to participate in the discussion with the meeting, the organizers will summarize opinions for discussion.',
      'Lỗi: Phiên làm việc đã kết thúc. Vui lòng đăng nhập lại':
        'Error: The session ends. Please sign in again',
      'Cổ đông vui lòng nhập nội dung muốn đăng ký thảo luận với Đại hội!':
        'Shareholder please enter the content you want to register for discussion or enter "I want to speak online"',
      'Đăng ký thảo luận': 'Register for discussion',
      'Nhập ý kiến': 'Enter comments',
      'ĐÃ XẢY RA LỖI': 'ERROR',
      'Lỗi hệ thống': 'System error',
      'Đã kết thúc phiên làm việc. Vui lòng đăng nhập lại để tiếp tục.':
        'The session ends. Please sign in again to continue',
      'Không tìm thấy tài nguyên': 'There are no resources',
      'Không thể kết nối với máy chủ. Vui lòng thử lại sau.':
        'Cannot connect to server. Please try again',
      'Không tán thành': 'Negative votes',
      'Không ý kiến': 'Abstention',
      'Thông tin Cổ đông': "Shareholder's information",
      'Link trực tuyến ĐHCĐ': 'Main link',
      'Link dự phòng': 'Extra link',
      'Ý kiến Cổ đông': "Shareholder's opinion",
      'Phiên thảo luận bắt đầu từ': 'Discussion starts from',
      đến: 'to',
      'Cổ đông vui lòng gửi ý kiến trong thời gian mở phiên thảo luận':
        'Please send your opinion during the discussion',
      'Phiên thảo luận đang đóng': 'Discussion session is closed',
      'Đã checkin': 'Checkin is done',
      'Phát biểu ý kiến': 'Show your opinion',
      'Click vào link dưới để phát biểu ý kiến':
        'Click the link below to speak',
      'Vui lòng nhập dữ liệu hợp lệ': 'Please enter valid data',
      'Vui lòng nhập thông tin': 'Please enter your information',
      'Vui lòng nhập đúng định dạng': 'Please enter in a proper form',
      'Giá trị phải lớn hơn': 'The value should be higher than',
      'Vui lòng nhập số lớn hơn 0': 'Please enter a number bigger than 0',
      'Vui lòng nhập 1 số nguyên': 'Please enter an integer',
      'Vui lòng nhập đúng định dạng số điện thoại':
        'Please enter correct phone number format',
      'Chờ xử lý': 'Waiting for progressing',
      'Đã xử lý': 'Processed',
      'Không xử lý': 'Failure',
      'Số điện thoại đã được sử dụng cho 1 nguời được ủy quyền khác':
        'This phone number is used by another authorized person',

      'Nội dung biểu quyết đang đóng': 'Voting time is closed!',
      'Bạn không được biểu quyết ở nội dung này': 'You cannot vote here.',
      'Cổ đông không được phép checkin': 'You are not allow to checkin',
      'Cổ đông không được phép biểu quyết': 'You are not allow to vote',
      'Gửi giấy ủy quyền bằng văn bản về trụ sở theo địa chỉ':
        'Send the Power of attorney in writing to the head office at the address',
      'Hình thức': 'Form',
      'Thời gian ủy quyền': 'Authorization time',
      'Ủy quyền trực tuyến': 'Online authorization',
      Bước: 'Step',
      'Nhập thông tin được ủy quyền': 'Enter authorized person information',
      'Cổ đông vui lòng tải lên giấy ủy quyền đã ký (đóng dấu) để hoàn thành ủy quyền':
        'Shareholders please upload signed/stamped power of attorney to complete the authorization',
      'CMND/CCCD/DKSH': 'ID card',
      'CP ủy quyền': 'Authorized shares',
      'Giấy ủy quyền': 'Power of attorney',
      'Tải mẫu ủy quyền': 'Download authorization form',
      'Tiếp tục': 'Continue',
      'Tải lên giấy ủy quyền': 'Upload power of attorney',
      'Bvote song hàng - Màu xanh cổ phiếu': 'Bvote partner - Stock blue',
      'Tiện ích cho Doanh nghiệp': 'Utilities for Business',
      'Lợi ích cho Cổ đông': 'Benefits for Shareholders',
      'Xác thực ủy quyền': 'Verify authorization',
      'Thông tin': 'Information',
      'thực hiện': 'Make',
      'biểu quyết': 'A Vote',
      'Thời gian sắp diễn ra:': 'Time to Open:',
      'Đã kết thúc - đóng cổng': 'Closed',
      'Thời gian đang diễn ra:': 'Open',
      'Thông tin đại diện tổ chức': "ORGANIZATION'S REPRESENTATIVE INFORMATION",
      'CMND/CCCD*': 'ID number/Passport*',
      'Họ và tên*': 'Full name*',
      'Số điện thoại*': 'Phone number*',
      'Xác nhận': 'Confirm',
      'Số điện thoại nhận OTP:': 'Phone number to receive OTP:',
      'Khai báo thông tin người đại diện tổ chức thành công':
        "Successfully updated the organization's representative information",
      'Tải lên giấy xác nhận đại diện tổ chức':
        'Upload organization representative confirmation',
    },
  },

  vi: {
    translation: {
      'Biểu quyết thông qua nội dung bầu cử':
        'Biểu quyết thông qua nội dung bầu cử',
      'Thời gian sắp diễn ra:': 'Thời gian sắp diễn ra:',
      'Đã kết thúc - đóng cổng': 'Đã kết thúc - đóng cổng',
      'Thời gian đang diễn ra:': 'Thời gian đang diễn ra:',
      'Ủy quyền toàn bộ số quyền biểu quyết cho:':
        'Ủy quyền toàn bộ số quyền biểu quyết cho:',
      'Xảy ra sự cố khi tải tệp. Vui lòng thử lại':
        'Xảy ra sự cố khi tải tệp. Vui lòng thử lại',
      'Người được ủy quyền không được nhận ủy quyền':
        'Người được ủy quyền không được nhận ủy quyền',
      'Tài khoản không tồn tại': 'Tài khoản không tồn tại.',
      'Quên mật khẩu?': 'Quên mật khẩu?',
      'Xác thực ủy quyền': 'Xác thực ủy quyền',
      'Đăng nhập cho Khách mời': 'Đăng nhập dành cho Khách mời',
      'Đăng nhập cho Cổ đông': 'Đăng nhập dành cho Cổ đông',
      'Đăng nhập biểu quyết': 'Đăng nhập biểu quyết',
      'Đại hội Cổ đông thường niên 2022': 'Đại hội Cổ đông thường niên 2022',
      'tài liệu Đại hội': 'Tài liệu Đại hội',
      'Tôi muốn thực hiện ủy quyền': 'Tôi muốn thực hiện ủy quyền',
      'Tôi xác nhận tham dự Đại hội': 'Tôi xác nhận tham dự Đại hội',
      'Hoàn thành thủ tục tham dự Đại hội':
        'Hoàn thành thủ tục tham dự Đại hội',
      'CP sở hữu/đại diện': 'CP sở hữu/đại diện',
      'Xem Đại hội trực tuyến': 'Xem Đại hội trực tuyến',
      'Cổ đông': 'Cổ đông',
      CP: 'CP',
      'Danh sách ủy quyền': 'Danh sách CĐ/ Người ủy quyền',
      'Khách mời': 'Khách mời',
      'Đăng nhập thất bại':
        'Đăng nhập thất bại. Quý Cổ đông vui lòng đăng nhập lại.',

      'Bạn có chắc chắn muốn gửi biểu quyết':
        'Quý Cổ đông có chắc chắn muốn gửi biểu quyết này?',
      'Nội dung biểu fquyết đã đóng': 'Nội dung biểu quyết đã đóng',
      'Cuộc họp không tồn tại': 'Cuộc họp không tồn tại',
      'Vui lòng nhập đầy đủ thông tin.': 'Vui lòng nhập đầy đủ thông tin.',
      'Gửi tất cả biểu quyết': 'Gửi tất cả biểu quyết',
      all: 'Tất cả',
      // 'Cổ đông chưa cập nhật SĐT Vui lòng liên hệ với Ban tổ chức để cập nhật thông tin':
      //   'Cổ đông chưa cập nhật SĐT. Vui lòng liên hệ với Ban tổ chức để cập nhật thông tin.',

      'Illegal arguments: string, undefined': 'Dữ liệu nhập vào không hợp lệ',

      input: {
        id_number: 'Tài khoản (CMND/CCCD/Số DKSH)',
        id_number_2: 'Tài khoản',
        password: 'Mật khẩu (Mật khẩu được gửi kèm theo thư mời)',
        password_2: 'Mật khẩu',
        authorized_name: 'Họ và tên người được uỷ quyền',
      },

      conf_time_modal: {
        desc_1: {
          part_1: 'Quý Cổ đông vui lòng quay lại từ sau',
          part_2: 'để tham dự Đại hội.',
        },
        desc_2: 'Xin cảm ơn Quý Cổ đông!',
        desc_3: 'Đại hội đã bế mạc. Xin cảm ơn quý Cổ đông đã tham gia !',
      },

      title: {
        home: {
          article: {
            meeting: 'thông tin',
            information: 'Đại hội',
          },
          topics: {
            meeting: 'tài liệu',
            documents: 'Đại hội',
          },
          shareholder: {
            voting: 'Thực hiện',
            _blank: 'Biểu quyết',
          },
        },
      },
      'Thực hiện biểu quyết': 'Thực hiện biểu quyết',

      'Dữ liệu không hợp lệ': 'Dữ liệu không hợp lệ',

      otp: 'OTP',
      password: 'Mật khẩu',
      'Hoàn thành': 'Hoàn thành',
      'Gửi mã OTP': 'Gửi mã OTP',
      'Tiếp tục với': 'Tiếp tục với',
      'Please check your SMS to get confirm OTP':
        'Mã OTP đã được gửi. Vui lòng kiểm tra SMS',

      validate: {
        required: 'Dữ liệu không được bỏ trống',
        pattern: {
          password: 'Mật khẩu phải có độ dài từ 6-50 ký tự',
          only_number: 'Dữ liệu chỉ được phép chứa chữ số',
        },
        wrong_pattern: 'Dữ liệu sai định dạng',
      },

      'Thao tác': 'Thao tác',
      'Thời gian': 'Thời gian',
      Từ: 'Từ',
      'Chưa có dữ liệu': 'Chưa có dữ liệu',
      'Cổ đông đã bầu cho nội dung này': 'Cổ đông đã bầu cho nội dung này',
      'Hướng dẫn dành cho Cổ đông': 'Hướng dẫn dành cho Cổ đông',
      'Địa điểm điều hành Đại hội': 'Địa điểm điều hành Đại hội',
      'Nội dung chính': 'Nội dung chính',
      'Quay lại': 'Quay lại',
      'Đồng ý': 'Đồng ý',
      'Vui lòng nhập mã OTP': 'Nhập mã OTP gửi về số điện thoại',
      'ĐÃ GỬI OTP THÀNH CÔNG': 'ĐÃ GỬI OTP THÀNH CÔNG',
      'Gửi OTP thành công':
        'Đã gửi OTP về số điện thoại của bạn. Vui lòng kiểm tra và điền mã OTP để tiếp tục',
      'THÔNG BÁO': 'THÔNG BÁO',
      'Nhập OTP': 'Nhập OTP',
      'Đăng nhập': 'Đăng nhập',
      'Vui lòng nhập mật khẩu': 'Vui lòng nhập mật khẩu',
      'Vui lòng nhập mật khẩu trong thư mời':
        'Vui lòng nhập mật khẩu trong thư mời.',
      'TRỞ VỀ TRANG CHỦ': 'TRỞ VỀ TRANG CHỦ',
      'Vui lòng điền đầy đủ thông tin': 'Vui lòng điền đầy đủ thông tin',
      'tiếp tục với mật khẩu': 'tiếp tục với mật khẩu',
      'tiếp tục với otp': 'tiếp tục với otp',
      'Vui lòng nhập số CMND/CCCD': 'Tên đăng nhập',
      'Không nhận được OTP?': 'Không nhận được OTP?',
      ' Gửi lại OTP': ' Gửi lại OTP',
      'quý Cổ đông vui lòng đăng nhập để tham gia biểu quyết':
        'Quý Cổ đông vui lòng đăng nhập để tham gia và biểu quyết',
      'Nhập nội dung': 'Nhập nội dung',
      'LƯU THÔNG TIN ỦY QUYỀN': 'LƯU THÔNG TIN ỦY QUYỀN',
      'Sau khi hoàn thành ủy quyền bạn không thể thay đổi các thông tin đã gửi cho Ban tổ chức Đại hội Vui lòng chọn Đồng ý nếu bạn đã chắc chắn với quyết định của mình Chọn quay lại để sửa thông tin Người được ủy quyền':
        'Sau khi hoàn thành ủy quyền, bạn không thể thay đổi các thông tin đã gửi cho Ban tổ chức Đại hội. Vui lòng chọn Đồng ý nếu bạn đã chắc chắn với quyết định của mình. Chọn quay lại để sửa thông tin Người được ủy quyền.',
      'Thông tin người được ủy quyền': 'Thông tin người được ủy quyền',
      'Họ và tên': 'Họ và tên',
      'Tài khoản': 'Tài khoản',
      'Mật khẩu': 'Mật khẩu',
      'Số điện thoại': 'Số điện thoại',
      'CMND/CCCD': 'CMND/CCCD',
      'Nhập số cổ phần ủy quyền': 'Nhập số cổ phần ủy quyền',
      'Nơi cấp': 'Nơi cấp',
      'Ngày cấp': 'Ngày cấp ',
      'Thông tin người ủy quyền': 'Thông tin người ủy quyền',
      'Số cổ phần có thể ủy quyền': 'Số cổ phần có thể ủy quyền',
      'Người đại diện phần vốn góp (dành cho Cổ đông là tổ chức)':
        'Người đại diện phần vốn góp (dành cho Cổ đông là tổ chức)',
      'Download không thành công': 'Download không thành công',
      'Họ tên': 'Họ tên',
      'Điện thoại': 'Điện thoại',
      'Cổ phần ủy quyền': 'Cổ phần ủy quyền',
      'Vui lòng lựa chọn ý kiến biểu quyết':
        'Vui lòng lựa chọn ý kiến biểu quyết!',
      'Trạng thái': 'Trạng thái',
      Lỗi: 'Lỗi',
      'Ủy quyền': 'Ủy quyền',
      Đóng: 'Đóng',
      'Để hoàn tất quá trình ủy quyền, quý Cổ đông vui lòng in thư ủy quyền và gửi đến:':
        'Để hoàn tất quá trình ủy quyền, quý Cổ đông vui lòng in thư ủy quyền và gửi đến:',
      'Địa chỉ': 'Địa chỉ',
      'để phục vụ cho ban tổ chức xử lý thông tin ủy quyền':
        'để phục vụ cho ban tổ chức xử lý thông tin ủy quyền',
      'ĐÃ LƯU THÔNG TIN ỦY QUYỀN': 'ĐÃ LƯU THÔNG TIN ỦY QUYỀN',
      'Cổ đông chưa cập nhật SĐT hoặc sai định dạng Vui lòng liên hệ với Ban tổ chức để cập nhật thông tin':
        'Cổ đông chưa cập nhật SĐT hoặc sai định dạng. Vui lòng liên hệ với Ban tổ chức để cập nhật thông tin. Xin cảm ơn!',
      'LƯU THÔNG TIN ỦY QUYỀN KHÔNG THÀNH CÔNG':
        'LƯU THÔNG TIN ỦY QUYỀN KHÔNG THÀNH CÔNG',
      'Địa chỉ nhận thư ủy quyền': 'Địa chỉ nhận thư ủy quyền',
      'Thời gian nhận thư ủy quyền': 'Thời gian nhận thư ủy quyền',
      'Tải mẫu form ủy quyền': 'Tải mẫu form ủy quyền',
      'Checkin thành công!': 'Checkin thành công!',
      'CHECKIN KHÔNG THÀNH CÔNG': 'CHECKIN KHÔNG THÀNH CÔNG',
      'Checkin Online': 'Checkin Online',
      'Chú ý':
        'Chú ý: Sau khi checkin, nếu không bỏ phiếu thì phiếu của quý Cổ đông sẽ được xem là phiếu không hợp lệ.',
      'Vui lòng chọn':
        'Vui lòng chọn "Checkin" nếu bạn đã chắc chắn muốn check in ngay bây giờ. Chọn quay lại để tiến hành checkin sau.',
      'Đăng xuất': 'Đăng xuất',
      'Gửi biểu quyết thành công': 'Gửi biểu quyết thành công',
      'BIỂU QUYẾT KHÔNG THÀNH CÔNG': 'BIỂU QUYẾT KHÔNG THÀNH CÔNG',
      'Biểu quyết thành công': 'Biểu quyết thành công',
      'Chi tiết': 'Chi tiết',
      'Sau khi gửi biểu quyết đến ban tổ chức':
        'Sau khi gửi biểu quyết đến ban tổ chức, bạn sẽ không thể sửa.',
      'Bạn có muốn tiếp tục?': 'Bạn có muốn tiếp tục?',
      'Đã hoàn thành': 'Đã hoàn thành',
      'Gửi biểu quyết': 'Gửi biểu quyết',
      'Qúy Cổ đông vui lòng nhấn Checkin để tiến hành checkin truớc khi biểu quyết':
        'Qúy Cổ đông vui lòng nhấn Checkin để tiến hành checkin truớc khi biểu quyết.',
      Hiện: 'Hiện',
      Ẩn: 'Ẩn',
      'GỬI BIỂU QUYẾT BẦU DỒN': 'GỬI BIỂU QUYẾT BẦU DỒN',
      '(Ủy quyền thêm)': '(Ủy quyền thêm)',
      'Tổng số phiếu có thể bầu': 'Tổng số phiếu có thể bầu',
      'Cổ đông đã nhập quá số phiếu có thể bầu':
        'Cổ đông đã nhập quá số phiếu có thể bầu',
      'Tổng số phiếu còn lại': 'Tổng số phiếu còn lại',
      'Tỷ lệ còn lại': 'Tỷ lệ còn lại',
      'Bầu đều': 'Bầu đều',
      'Tích để bầu đều': 'Tích để bầu đều',
      'Nhập số phiếu bầu': 'Nhập số phiếu bầu',
      'Nhập tỷ lệ bầu (%)': 'Nhập tỷ lệ bầu (%)',
      'Số phiếu không hợp lệ': 'Số phiếu không hợp lệ',
      Còn: 'Còn',
      'mục chưa bỏ phiếu': 'mục chưa bỏ phiếu',
      'Đã hoàn thành bỏ phiếu': 'Đã hoàn thành bỏ phiếu',
      'Cổ đông, đại diện cho': 'Cổ đông, đại diện cho',
      'cổ phần': 'cổ phần',
      'Mỗi cổ phần tương ứng với một phiếu biểu quyết':
        'Mỗi cổ phần tương ứng với một phiếu biểu quyết.',
      'Tên doanh nghiệp': 'Tên doanh nghiệp',
      'Tổng tỷ lệ bầu không thể quá 100%': 'Tổng tỷ lệ bầu không thể quá 100%',
      'Mã số doanh nghiệp': 'Mã số doanh nghiệp',
      'Thời gian họp': 'Khai mạc',
      'Bế mạc': 'Bế mạc',
      'Địa điểm họp': 'Địa điểm điều hành Đại hội',
      'Tổng số Cổ đông tham dự': 'Tổng số Cổ đông',
      'Chủ tọa Đại hội': 'Chủ tọa Đại hội',
      'Thư ký Đại hội': 'Thư ký Đại hội',
      'Tán thành': 'Tán thành',
      'Gửi tất cả': 'Gửi biểu quyết',
      'Vui lòng chọn ít nhất 1 biểu quyết trước khi biểu quyết':
        'Vui lòng chọn ít nhất 1 biểu quyết trước khi biểu quyết',
      'Nội dung': 'Nội dung',
      'Đã biểu quyết trước đó': 'Đã biểu quyết trước đó.',
      'Chưa chọn biểu quyết': 'Chưa chọn biểu quyết',
      'Biểu quyết không thành công': 'Biểu quyết không thành công',
      'Vui lòng chọn biểu quyết cho câu hỏi này trước':
        'Vui lòng chọn biểu quyết cho câu hỏi này trước',
      'Chưa biểu quyết': 'Chưa biểu quyết',
      'Đã biểu quyết': 'Đã biểu quyết',
      STT: 'STT',
      'Biểu quyết': 'Biểu quyết',
      'Tải danh sách ý kiến lỗi': 'Tải danh sách ý kiến lỗi',
      'Danh sách ý kiến': 'Danh sách ý kiến',
      'Ý kiến': 'Ý kiến',
      'Ngày gửi': 'Ngày gửi',
      'Bầu cử': 'Bầu cử',
      'Số CMND/CCCD/MSDN': 'CMND/CCCD/ Số ĐKSH',
      'Số cổ phần sở hữu': 'Cổ phần sở hữu/ đại diện',
      'Thông qua thủ tục khai mạc Đại hội':
        'Biểu quyết thông qua thủ tục khai mạc Đại hội',
      'Biểu quyết thông qua các tờ trình':
        'Biểu quyết thông qua các Báo cáo/ Tờ trình',
      'Biểu quyết thông qua biên bản và nghị quyết cuộc họp':
        'Biểu quyết thông qua Biên bản và Nghị quyết Đại hội',
      'Nội dung (bầu dồn phiếu)': 'Nội dung (bầu dồn phiếu)',
      'Cổ đông tiến hành gửi biểu quyết cho':
        'Cổ đông tiến hành gửi biểu quyết cho',
      mục: 'mục',
      'Tổng số nội dung tán thành': 'Tổng số nội dung tán thành',

      'Tổng số nội dung không tán thành': 'Tổng số nội dung không tán thành',
      'Tổng số nội dung không ý kiến': 'Tổng số nội dung không ý kiến',
      'Tổng số nội dung chưa biểu quyết': 'Tổng số nội dung chưa biểu quyết',
      'Nội dung Cổ đông không biểu quyết':
        'Nội dung Cổ đông không biểu quyết sẽ bị tính là phiếu không hợp lệ cho riêng nội dung đó.',
      'Cổ đông có chắc chắn gửi biểu quyết của mình?':
        'Cổ đông có chắc chắn gửi biểu quyết của mình?',
      'Tải xuống file kết quả biểu quyết': 'Tải xuống file kết quả biểu quyết',
      'Thông tin Đại hội': 'Thông tin Đại hội',

      'Trạng thái biểu quyết': 'Trạng thái biểu quyết',

      'Phiên làm việc đã kết thúc': 'Phiên làm việc đã kết thúc',
      'Phiên đã kết thúc hoặc không hợp lệ Vui lòng đăng nhập lại để tiếp tục sử dụng':
        'Phiên đã kết thúc hoặc không hợp lệ. Vui lòng đăng nhập lại để tiếp tục sử dụng.',
      'Cảm ơn quý Cổ đông':
        'Cảm ơn quý Cổ đông đã đăng ký tham gia thảo luận với Đại hội, ban tổ chức sẽ tổng hợp lại ý kiến để tiến hành thảo luận.',
      'Lỗi: Phiên làm việc đã kết thúc. Vui lòng đăng nhập lại':
        'Lỗi: Phiên làm việc đã kết thúc. Vui lòng đăng nhập lại',
      'Cổ đông vui lòng nhập nội dung muốn đăng ký thảo luận với Đại hội!':
        'Cổ đông vui lòng nhập nội dung muốn đăng ký thảo luận hoặc nhập "Tôi muốn phát biểu trực tuyến"',
      'Đăng ký thảo luận': 'Đăng ký thảo luận',
      'Nhập ý kiến': 'Nhập ý kiến',
      'ĐÃ XẢY RA LỖI': 'ĐÃ XẢY RA LỖI',
      'Lỗi hệ thống': 'Lỗi hệ thống',
      'Đã kết thúc phiên làm việc. Vui lòng đăng nhập lại để tiếp tục.':
        'Đã kết thúc phiên làm việc. Vui lòng đăng nhập lại để tiếp tục.',
      'Không tìm thấy tài nguyên': 'Không tìm thấy tài nguyên',
      'Không thể kết nối với máy chủ. Vui lòng thử lại sau.':
        'Không thể kết nối với máy chủ. Vui lòng thử lại sau.',
      'Không tán thành': 'Không tán thành',
      'Không ý kiến': 'Không ý kiến',
      'Thông tin Cổ đông': 'Thông tin Cổ đông',
      'Link trực tuyến ĐHCĐ': 'Link trực tuyến ĐHCĐ',
      'Link dự phòng': 'Link dự phòng',
      'Ý kiến Cổ đông': 'Ý kiến Cổ đông',
      'Phiên thảo luận bắt đầu từ': 'Phiên thảo luận bắt đầu từ',
      đến: 'đến',
      'Cổ đông vui lòng gửi ý kiến trong thời gian mở phiên thảo luận':
        'Cổ đông vui lòng gửi ý kiến trong thời gian mở phiên thảo luận',
      'Phiên thảo luận đang đóng': 'Phiên thảo luận đang đóng',
      'Đã checkin': 'Đã checkin',

      'Phát biểu ý kiến': 'Phát biểu ý kiến',
      'Click vào link dưới để phát biểu ý kiến':
        'Click vào link dưới để phát biểu ý kiến',
      'Vui lòng nhập dữ liệu hợp lệ': 'Vui lòng nhập dữ liệu hợp lệ',
      'Vui lòng nhập thông tin': 'Vui lòng nhập thông tin',
      'Vui lòng nhập đúng định dạng': 'Vui lòng nhập đúng định dạng',
      'Giá trị phải lớn hơn': 'Giá trị phải lớn hơn',
      'Vui lòng nhập số lớn hơn 0': 'Vui lòng nhập số lớn hơn 0',
      'Vui lòng nhập 1 số nguyên': 'Vui lòng nhập 1 số nguyên',
      'Vui lòng nhập đúng định dạng số điện thoại':
        'Vui lòng nhập đúng định dạng số điện thoại',
      'Chờ xử lý': 'Chờ xử lý',
      'Đã xử lý': 'Đã xử lý',
      'Không xử lý': 'Thất bại',
      'Nếu muốn thực hiện biểu quyết tất cả số phiếu bầu, Cổ đông vui lòng thực hiện biểu quyết cho từng Cổ đông trong danh sách nhận uỷ quyền':
        'Nếu muốn thực hiện biểu quyết tất cả số phiếu bầu, Cổ đông vui lòng thực hiện biểu quyết cho từng Cổ đông trong danh sách nhận uỷ quyền.',
      'Tổng số phiếu bầu còn lại là số phiếu của các Cổ đông uỷ quyền không thoả mãn việc chia đều':
        'Tổng số phiếu bầu còn lại là số phiếu của các Cổ đông uỷ quyền không thoả mãn việc chia đều.',
      'Đã gửi biểu quyết': 'Đã gửi biểu quyết',
      'Nội dung này chưa mở biểu quyết': 'Nội dung biểu quyết đang đóng',
      'Không thể thay đổi biểu quyết đã gửi':
        'Cổ đông đã bầu cho nội dung này.',
      'Số điện thoại đã được sử dụng cho 1 nguời được ủy quyền khác':
        'Số điện thoại đã được sử dụng cho 1 nguời được ủy quyền khác',
      'Nội dung biểu quyết đang đóng': 'Nội dung biểu quyết đang đóng',
      'Bạn không được biểu quyết ở nội dung này':
        'Bạn không được biểu quyết ở nội dung này',
      'Cổ đông không được phép checkin': 'Cổ đông không được phép checkin',
      'Cổ đông không được phép biểu quyết':
        'Cổ đông không được phép biểu quyết',
      'Gửi giấy ủy quyền bằng văn bản về trụ sở theo địa chỉ':
        'Gửi giấy ủy quyền bằng văn bản về trụ sở theo địa chỉ',
      'Hình thức': 'Hình thức',
      'Thời gian ủy quyền': 'Thời gian ủy quyền',
      'Ủy quyền trực tuyến': 'Ủy quyền trực tuyến',
      'Cổ đông không thể gửi ý kiến trong thời điểm này':
        'Phiên thảo luận đang đóng.',
      Bước: 'Bước',
      'Nhập thông tin được ủy quyền': 'Nhập thông tin người được ủy quyền',
      'Cổ đông vui lòng tải về giấy ủy quyền để thực hiện ký (đóng dấu)':
        'Cổ đông vui lòng tải về giấy uỷ quyền để thực hiện ký/đóng dấu',
      'Cổ đông vui lòng tải lên giấy ủy quyền đã ký (đóng dấu) để hoàn thành ủy quyền':
        'Cổ đông vui lòng tải lên giấy uỷ quyền đã ký/đóng dấu để hoàn thành uỷ quyền',
      'CMND/CCCD/DKSH': 'CMND/CCCD/DKSH',
      'CP ủy quyền': 'CP ủy quyền',
      'Giấy ủy quyền': 'Giấy ủy quyền',
      'Tải mẫu ủy quyền': 'Tải mẫu ủy quyền',
      'Tiếp tục': 'Tiếp tục',
      'Tải lên giấy ủy quyền': 'Tải lên giấy ủy quyền',
      'Bvote song hàng - Màu xanh cổ phiếu':
        'Bvote song hàng - Màu xanh cổ phiếu',
      'Tiện ích cho Doanh nghiệp': 'Tiện ích cho Doanh nghiệp',
      'Lợi ích cho Cổ đông': 'Lợi ích cho Cổ đông',
      'Thông tin đại diện tổ chức': 'Thông tin đại diện tổ chức',
      'CMND/CCCD*': 'CMND/CCCD*',
      'Họ và tên*': 'Họ và tên*',
      'Số điện thoại*': 'Số điện thoại*',
      'Xác nhận': 'Xác nhận',
      'Số điện thoại nhận OTP:': 'Số điện thoại nhận OTP:',
      'Khai báo thông tin người đại diện tổ chức thành công':
        'Khai báo thông tin người đại diện tổ chức thành công',
      'Tải lên giấy xác nhận đại diện tổ chức':
        'Tải lên giấy xác nhận đại diện tổ chức',
    },
  },
};

export default translate;
